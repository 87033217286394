@tailwind base;
@tailwind components;
@tailwind utilities;

.loader-dots div {
  animation-timing-function: linear;
}
.loader-dots div:nth-child(1) {
  right: 8px;
  animation: loader-dots1 1.0s infinite;
}
.loader-dots div:nth-child(2) {
  right: 8px;
  animation: loader-dots2 1.0s infinite;
}
.loader-dots div:nth-child(3) {
  right: 32px;
  animation: loader-dots2 1.0s infinite;
}
.loader-dots div:nth-child(4) {
  right: 56px;
  animation: loader-dots3 1.0s infinite;
}
@keyframes loader-dots1 {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}
@keyframes loader-dots2 {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-24px, 0);
  }
}
@keyframes loader-dots3 {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(0);
  }
}
